import React, { useState,useEffect  } from "react";
import "../styles/components/footer.css";
import { Link } from "react-router-dom";
import { faSmile } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
function Footer({theme}) {
  let navigate = useNavigate();
  return (
    <div className={`footer ft-${theme}`}>
        <div className="footerContainer">
            <div className="footer-content">
              <div className="footer-links">
                <div className="footer-nav">
                  <h3>
                    Go to
                  </h3>
                  <Link to="/">Home</Link> <br/>
                  <Link to="/festivals">Festivals</Link> <br/>
                  <Link to="/about">About</Link> <br/>
                  <Link to="/faq">FAQ</Link> <br/>
                  <Link to="/terms">Terms</Link> <br/> 
                  <Link to="/privacy">Privacy</Link> <br/>
                </div>
                <div className="footer-social">
                  <h3>
                    Follow Us
                  </h3>
                  <a href="https://www.instagram.com/">instagram</a><br/>
                  <a href="https://www.facebook.com/">facebook</a><br/>
                  <a href="https://www.linkedin.com/">linkedin</a><br/>
                </div> 
              </div>
              <div className="footer-contact">
                 <h2 className={`footer-contact-title ft-title-${theme}`} onClick={()=>navigate("/contact")}>
                    Get In Touch
                    <FontAwesomeIcon icon={faSmile} className="footer-icon"/>
                 </h2>
              </div>
            </div>
            <div className="footer-copyright">
                <div className="footer-logo">
                    <p>
                        &copy; 2024 <br/>Allkka. All rights reserved.
                    </p>
                </div>
                <div className="footer-credits">
                    <p>
                        By Music Lovers, For Music Lovers <br/>
                        A 
                        <a href="https://www.johann-dev.me/" target="_blank" rel="noreferrer" className="footer-credits-link">
                          Johann Vargas 
                        </a>
                        Production
                    </p>
                </div>
            </div>
        </div>           
    </div>   
  );
}

export default Footer;