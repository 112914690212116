import React, { useState,useEffect,useContext  } from "react";
import "../styles/components/navbar.css";
import { Link,useNavigate } from "react-router-dom";
import {ReactComponent as MeltyFace} from '../assets/svg/melted-smiley-face.svg';
import { UserContext } from '../context/userContext';
import { faSun, faMoon, faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Navbar() {
  const { theme, setTheme, clearCache, isLogged } = useContext(UserContext);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  let navigate = useNavigate();
  const toggleTheme = () => {
    setTheme(theme === 'dark' ? 'light' : 'dark');
  }
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  

  const HandleButtonClick = async (action) => {
    if (action === 'get-started') {
      if (!isLogged) {
        alert('Please Connect to Spotify to get started');
      }
      navigate('/Spotify');
    }
    else if (action === 'log-out') {
      const response = clearCache();
      if (response) {
        navigate('/');
      }
    }
  }
  return (
    <div className="navbar">
        <div className='logo'>
          <h1 onClick={() => navigate('/')} className={`lg-${theme === 'dark' ? 'dark' : 'light'}`}>
              ALLKKA.
            </h1>
            <div className={`tagline tg-${theme === 'dark' ? 'dark' : 'light'}`}>
            <MeltyFace className="melty-face" />
            <p>
                By Music Lovers, For Music Lovers.
            </p>
            </div>
        </div>
        {isMobile ? (
        <MobileNavbar toggleTheme={toggleTheme} theme={theme} isLogged={isLogged} HandleButtonClick={HandleButtonClick} />
      ) : (
        <DesktopNavbar theme={theme} toggleTheme={toggleTheme} isLogged={isLogged} HandleButtonClick={HandleButtonClick} />
      )}
        
      </div>   
  );
}

export default Navbar;

const MobileNavbar = ({ toggleTheme, theme, isLogged, HandleButtonClick }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="mobile-navbar">
      <FontAwesomeIcon icon={faBars} onClick={() => setIsOpen(!isOpen)} className="bars-icon" />
      <div className={`mobile-navbar-links ${isOpen ? 'nv-open' : 'nv-close'} mobile-nv-${theme}`}>
        <div className="mobile-navbar-header">
          <FontAwesomeIcon icon={faSun} onClick={toggleTheme} className="theme-toggler" />
          <FontAwesomeIcon icon={faXmark} onClick={() => setIsOpen(!isOpen)} className="nv-close-icon" />
        </div>
        <Link to="/festivals">Festivals</Link>
        <Link to="/about">About</Link>
        <Link to="/contact">Get In Touch</Link>
        {
          isLogged? (
            <button  className="get-started" onClick={() => HandleButtonClick('log-out')}>
              Log Out
            </button>
          ) : (
            <button  className="get-started" onClick={() => HandleButtonClick('get-started')}>
              Get Started
            </button>
          )
        }
      </div>
    </div>
  );
}

const DesktopNavbar = ({theme, toggleTheme, isLogged, HandleButtonClick }) => {

  return (
    <div className="nav-links">
      <FontAwesomeIcon icon={theme === 'dark' ? faSun : faMoon} onClick={toggleTheme} className={`theme-toggler tg-${theme === 'dark' ? 'dark' : 'light'}`} />
          <div className={`nav-links-container nl-${theme === 'dark' ? 'dark' : 'light'}`}>
          <Link to="/festivals">Festivals</Link>
          <span className="divider">|</span>
          <Link to="/about">About</Link>
          <span className="divider">|</span>
          <Link to="/contact">Get In Touch</Link>
          </div>
          {
            isLogged ? (
              <button  className="get-started" onClick={() => HandleButtonClick('log-out')}>
                Log Out
              </button>
            ) : (
              <button  className="get-started" onClick={() => HandleButtonClick('get-started')}>
                Get Started
              </button>
            )
          }
        </div>
  );
}