import React, { useContext, useState, useEffect, createContext, useRef } from 'react';
import api from '../hooks/api';

const UserContext = createContext();

const UserProvider = ({ children }) => {
    const cache = useRef(new Map());
    const [theme, setTheme] = useState('light');
    const [isLogged, setIsLogged] = useState(false);
    // Helper function to save cache data to local storage
    const saveToLocalStorage = (key, value) => {
        localStorage.setItem(key, JSON.stringify(value));
    };

    // Helper function to load cache data from local storage
    const loadFromLocalStorage = (key) => {
        const storedValue = localStorage.getItem(key);
        try {
            return storedValue ? JSON.parse(storedValue) : null;
        } catch (e) {
            console.error(`Error parsing localStorage key "${key}":`, e);
            return null;
        }
    };
    async function checkExpiry ({spotifyToken,spotifyRefreshToken, spotifyUser, expiresIn}) {
        const currentTime = new Date().getTime();
        const expiryTime = new Date(expiresIn).getTime();
        if (currentTime > expiryTime) {
            await refreshAccessToken(spotifyToken, spotifyRefreshToken);
            cache.current.set('spotifyUser', spotifyUser);
        }
        else {
            cache.current.set('spotifyToken', spotifyToken);
            cache.current.set('spotifyRefreshToken', spotifyRefreshToken);
            cache.current.set('spotifyUser', spotifyUser);
            cache.current.set('expiresIn', expiresIn);
        }
    }

    useEffect(() => {
        // On mount, try to load Spotify tokens and user from local storage
        const spotifyToken = loadFromLocalStorage('spotifyToken');
        const spotifyRefreshToken = loadFromLocalStorage('spotifyRefreshToken');
        const spotifyUser = loadFromLocalStorage('spotifyUser');
        const expiresIn = loadFromLocalStorage('expiresIn');
        if (spotifyToken){
            checkExpiry({spotifyToken, spotifyRefreshToken, spotifyUser, expiresIn});
        }
    }, []);
    useEffect(() => {
    // Update isLoggedIn state whenever the cache changes
    setIsLogged(!!cache.current.get('spotifyToken'));
    }, [cache.current]);

    const checkSpotifyToken = async () => {
        const code = new URLSearchParams(window.location.search).get('code');
        
        if (code && !cache.current.has('spotifyToken')) {
            const response = await api.post('/api/auth/spotifyLogin', {
                code,
            });
            const { accessToken, refreshToken, user, expiresIn  } = response.data;

            // Set cache
            cache.current.set('spotifyToken', accessToken);
            cache.current.set('spotifyRefreshToken', refreshToken);
            cache.current.set('spotifyUser', user);
            //calculate expiry time
            const currentTime = new Date().getTime();
            const expiryTime = currentTime + expiresIn * 1000;
            cache.current.set('expiresIn', expiryTime);

            // Save to local storage
            saveToLocalStorage('spotifyToken', accessToken);
            saveToLocalStorage('spotifyRefreshToken', refreshToken);
            saveToLocalStorage('spotifyUser', user);
            saveToLocalStorage('expiresIn', expiryTime);
            // Clean up the URL
            window.history.pushState({}, null, '/Spotify');
            setIsLogged(true);
            return { accessToken, user , refreshToken, expiresIn};
        } else if (cache.current.has('spotifyToken')) {
            return {
                accessToken: cache.current.get('spotifyToken'),
                user: cache.current.get('spotifyUser')
            };
        }

        // Clean up the URL
        window.history.pushState({}, null, '/Spotify');
        return {}; 
    };
    
    async function refreshAccessToken(passedToken, passedRefreshToken) {
        try {
            const oldAccessToken = cache.current.get('spotifyToken') ? cache.current.get('spotifyToken'): passedToken;
            const oldRefreshToken = cache.current.get('spotifyRefreshToken') ? cache.current.get('spotifyRefreshToken'): passedRefreshToken;
            const response = await api.get(`/api/refreshAccessToken/${oldAccessToken}/${oldRefreshToken}`);
            const { accessToken, refreshToken, expiresIn } = response.data;
            // Update cache
            cache.current.set('spotifyToken', accessToken);
            cache.current.set('spotifyRefreshToken', refreshToken);
            // calculate expiry time
            const currentTime = new Date().getTime();
            const expiryTime = currentTime + expiresIn * 1000;
            cache.current.set('expiresIn', expiryTime);
            // Save to local storage
            saveToLocalStorage('spotifyToken', accessToken);
            saveToLocalStorage('spotifyRefreshToken', refreshToken);
            saveToLocalStorage('expiresIn', expiryTime);
            setIsLogged(true);
            return accessToken;
        }
        catch (err) {
            console.error(err);
            return null;
        }
    }
    async function clearCache () {
        try {
            cache.current.clear();
            // Clear local storage
            localStorage.clear();
            setIsLogged(false);
            return true;
        }
        catch (err) {
            console.error(err);
            return false;
        }
    }

    return (
        <UserContext.Provider value={{
            cache,
            checkSpotifyToken,
            refreshAccessToken,
            clearCache,
            theme,
            setTheme,
            isLogged,
        }}>
            {children}
        </UserContext.Provider>
    );
};

export {
    UserContext,
    UserProvider
};
