import React, { useContext } from 'react';
import Navbar from './navbar';
import Footer from './footer';
import { UserContext } from '../context/userContext';
import '../styles/components/layout.css';
const Layout = ({ children }) => {
    const { theme } = useContext(UserContext);
    return (
        <div className={`layout ${theme}`}>
            <Navbar />
                {children}
            <Footer 
                theme={theme}
            />
        </div>
    )
}

export default Layout;