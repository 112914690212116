import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import reportWebVitals from './reportWebVitals';
import { UserProvider} from './context/userContext';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Layout from './components/layout';
import HttpsRedirect from 'react-https-redirect';
const Spotify = React.lazy(() => import('./pages/spotify'));
const App = React.lazy(() => import('./App'));
const About = React.lazy(() => import('./pages/about'));
const Generate = React.lazy(() => import('./pages/generate'));
const FAQs = React.lazy(() => import('./pages/faqs'));
const Festival = React.lazy(() => import('./pages/festival'));
const FestivalList = React.lazy(() => import('./pages/festivalList'));
const PrivacyPolicy = React.lazy(() => import('./pages/privacyPolicy'));
const YouTube = React.lazy(() => import('./pages/youtube'));
const NotFound = React.lazy(() => import('./pages/notFound'));
const Contact = React.lazy(() => import('./pages/contact'));
const Terms = React.lazy(() => import('./pages/terms'));
const router = createBrowserRouter([
  {
    
    path: "/",
    element: <Layout><App/></Layout>,
   
  },
  
  {
    path: "/Spotify",
    element: <Layout><Spotify/></Layout>,
  },
  {
    path: "/faq",
    element: <Layout><FAQs/></Layout>,
  },
  {
    path: "/privacy",
    element: <Layout><PrivacyPolicy/></Layout>,
  },
  {
    path: "/terms",
    element: <Layout><Terms/></Layout>,
  },
  {
    path: "/About",
    element: <Layout><About/></Layout>,
  },
  {
    path: "/Generate",
    element: <Generate/>,
  },
  {
    path: "/YouTube",
    element: <YouTube/>,
  },
  {
    path: "/festivals",
    element:
    <Layout><Festival/></Layout>, 
  },
  {
    path: "/contact",
    element: <Layout><Contact/></Layout>,
  },
  {
    path: "*",
    element: <Layout><NotFound/></Layout>,
  }
]);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <HttpsRedirect>
      <Suspense fallback={<div>Loading...</div>}>
        <UserProvider>
          <RouterProvider router={router}/>
        </UserProvider>
      </Suspense>
    </HttpsRedirect>
);


reportWebVitals();
